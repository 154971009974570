import { t } from "i18next";
import { Table } from "react-bootstrap";
import { FiEdit, FiMapPin } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import IDevicesProp from "../../../Interfaces/Props/IDevicesProp";

import DeviceMap from "./DeviceMap";

const DeviceList = (prop: IDevicesProp) => {
  const navigate = useNavigate();

  // Add a unique key to the DeviceMap component

  return (
    <>
      {prop && prop.devices.length > 0 ? (
        <div>
          <Table bordered hover>
            <thead>
              <tr>
                <th className="table-header-row">{t("deviceName")}</th>
                <th className="table-header-row text-nowrap">
                  {t("lastActive")}
                </th>
                <th className="table-header-row">{t("voltage")}</th>
                <th className="table-header-row">{t("temperature")}</th>
                <th className="table-header-row">{t("humidity")}</th>
                <th className="table-header-row">{t("direction")}</th>
                <th className="table-header-row">{t("localization")}</th>
                <th className="table-header-icon-column table-header-row"></th>
              </tr>
            </thead>
            <tbody>
              {prop.devices?.map((device, index) => {
                return (
                  <tr key={index}>
                    <td>{device.deviceName}</td>
                    <td>
                      {device.lastActive
                        ?.toString()
                        .replace("T", " ")
                        .substring(0, 16)}
                    </td>
                    <td className={`custom-background-${device.color}`}>
                      {device.color === "grey" ? "-" : device.voltage}
                    </td>
                    <td>{device.temperature}</td>
                    <td>{device.humidity}</td>
                    <td>{device.direction}</td>
                    <td
                      className="article-header-icon icon-pointer"
                      onClick={() => {
                        window.open(device.googleMapsUrl, "_blank");
                      }}
                    >
                      <center>
                        <FiMapPin size="20"></FiMapPin>
                      </center>
                    </td>
                    <td
                      className="article-header-icon icon-pointer"
                      onClick={() => {
                        navigate(`/devices/device?deviceId=${device.id}`);
                      }}
                    >
                      <center>
                        <FiEdit size="20"></FiEdit>
                      </center>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <DeviceMap devices={prop.devices}></DeviceMap>
        </div>
      ) : (
        <div className="container p-3">
          <div className="container-fluid col-sm-12 col-lg-12">
            <div className="alert alert-light m-2 p-2" role="alert">
              <center>{t("no-devices-found")}</center>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeviceList;
