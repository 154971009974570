import { RiArrowGoBackFill, RiRefreshLine } from "react-icons/ri";
import { IHeader } from "../../../Interfaces/IHeader";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const Title = (header: IHeader) => {
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center p-3 mt-3 article-header rounded shadow-sm border border-top-0 justify-content-between">
      <div className="lh-100 d-flex align-items-center">
        <header.icon size="28" className="article-header-icon"></header.icon>

        <h6 className="mb-0 text-dark-50 lh-100"> {header.message}</h6>

        {header.document && (
          <small>
            {t("forDocument")} {header.document}
          </small>
        )}
      </div>

      <div className="lh-100 d-flex align-items-center">
        {header.showGoBackIcon === true && (
          <RiArrowGoBackFill
            size="28"
            className="article-header-icon icon-pointer"
            onClick={() => {
              navigate(-1);
            }}
          ></RiArrowGoBackFill>
        )}

        {header.showRefreshIcon === true && (
          <RiRefreshLine
            size="28"
            className="article-header-icon icon-pointer"
            onClick={() => {
              navigate(0);
            }}
          ></RiRefreshLine>
        )}

        {header.additionalInfo && header.additionalInfo != undefined && (
          <h6 className="mb-0 text-dark-50 lh-100"> {header.additionalInfo}</h6>
        )}
      </div>
    </div>
  );
};

export default Title;
