import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const HeaderNavbar = () => {
  const { t } = useTranslation();
  return (
    <Nav className="ms-auto">
      {/* <Nav.Link className="my-2 mx-2" href="#/">
        {t("homePage")}
      </Nav.Link>
      <Nav.Link className="my-2 mx-2" href="#/documents">
        {t("listOfDocuments")}
      </Nav.Link>
      <Nav.Link className="my-2 mx-2" href="#/related">
        {t("relatedDocuments")}
      </Nav.Link> */}
    </Nav>
  );
};

export default HeaderNavbar;
