import { AxiosError } from "axios";
import log from "loglevel";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ILoginUser } from "../../../Interfaces/Users/ILoginUser";
import { IToken } from "../../../Interfaces/Users/IToken";
import {
  loginWithMicrosoftToken,
  redirectToMicrosoftLoginPage,
  restLogin,
} from "../../../api/auth/authClient";
import ErrorContainer from "../../moleculars/Containers/ErrorContainer";
import LoadingContainer from "../../moleculars/Containers/LoadingContainer";

const LoginForm = () => {
  const { t, i18n } = useTranslation();

  const queryParameters = new URLSearchParams(document.location.search);

  const queryParametersHash = new URLSearchParams(
    window.location.hash.replace("#", "")
  );

  const [User, setLoginUser] = useState<Partial<ILoginUser>>();

  let accessToken = queryParametersHash.get("access_token");
  let state = queryParametersHash.get("state");

  let enableQuery = false;
  const { isLoading, isSuccess, isError, data, error, refetch, isFetching } =
    useQuery<IToken, AxiosError>(
      "login-user",
      async () => {
        let res = await restLogin(User);
        return res;
      },
      {
        enabled: enableQuery,
        retryDelay: 100,
        onError: (error) =>
          log.error(`Login error = ${User?.login}: ${error.message}`),
        onSuccess(data) {
          if (data.token) {
            log.trace(process.env.PUBLIC_URL);
            window.location.href = "http://thor.erplast.pl/";
          }
        },
      }
    );

  useEffect(() => {
    if (accessToken && state) {
      let loginParams: IToken = {
        token: accessToken,
      };

      loginWithMicrosoftToken(loginParams)
        .then(
          (response) => {
            if (response.token) {
              let redirectPath = process.env.PUBLIC_URL;

              window.location.href = redirectPath;
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log(error);
        });
    }
  }, [accessToken]);

  const loginUserWithMicrosoft = async (event: SyntheticEvent) => {
    event.preventDefault();
    await redirectToMicrosoftLoginPage();
  };

  const loginUser = async (event: SyntheticEvent) => {
    event.preventDefault();

    if ((await restLogin(User)).token) {
      let redirectPath = process.env.PUBLIC_URL;

      window.location.href = redirectPath;
    }
  };

  const onSubmitHandler = async (event: SyntheticEvent) => {
    event.preventDefault();
    enableQuery = true;
    refetch();
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    event.preventDefault();
    if (event.keyCode === 13 || event.which === 13) {
      onSubmitHandler(event);
    }
  };

  return (
    <>
      {isError && <ErrorContainer code={error?.response?.status} />}
      {(isLoading || isFetching) && <LoadingContainer />}
      <Form className="p-4" onSubmit={onSubmitHandler}>
        <Form.Floating className="mb-3">
          <FloatingLabel
            controlId="floatingTextarea"
            label={t("login")}
            className="mb-3"
          >
            <Form.Control
              className="border-0 border-bottom "
              type="text"
              placeholder="Login"
              onChange={(e) => setLoginUser({ ...User, login: e.target.value })}
            />
          </FloatingLabel>
        </Form.Floating>
        <Form.Floating className="mb-3">
          <FloatingLabel
            controlId="floatingPassword"
            label={t("password")}
            className="mb-3"
          >
            <Form.Control
              className="border-0 border-bottom "
              type="password"
              placeholder="Password"
              onChange={(e) =>
                setLoginUser({ ...User, password: e.target.value })
              }
            />
          </FloatingLabel>
        </Form.Floating>

        <br />

        <Row>
          <Col xs="12" md="12">
            <Button variant="primary" type="submit" className="w-100">
              {t("signIn")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default LoginForm;
