import { t } from "i18next";
import React from "react";
import { IErrorProp } from "../../../Interfaces/Props/IErrorProp";
import DangerAlert from "../../atoms/alerts/DangerAlert";
import FluidContainer from "./FluidContainer";
const getMessage = (code: number | undefined) => {
  switch (code) {
    case 404:
      return "notFoundMessage";
    case 400:
      return "badRequestError";
    case 401:
      return "unauthorizedError";
    case 415:
      return "unauthorizedError";
    default:
      return "defaultError";
  }
};

const ErrorContainer = (error: IErrorProp) => {
  return (
    <FluidContainer>
      <DangerAlert message={t(getMessage(error.code))} />
    </FluidContainer>
  );
};

export default ErrorContainer;
