const LogoHeader = () => {
  return (
    <div className="d-flex align-items-center p-3 mt-3 article-header justify-content-center rounded border-top-0 ">
      <img
        className=""
        src={process.env.PUBLIC_URL + "/img/logo.png"}
        alt=""
        height="48"
      ></img>
    </div>
  );
};

export default LogoHeader;
