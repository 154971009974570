import { useTranslation } from "react-i18next";
import { Route } from "use-react-router-breadcrumbs";
import ProtectedRoute from "./components/moleculars/routers/authRoute";
import Device from "./components/organisms/Articles/thor/Device";
import Devices from "./components/organisms/Articles/thor/Devices";
import WorkingGroups from "./components/organisms/Articles/thor/WorkingGroups";
import Layout from "./components/organisms/layouts/Layout";
import LoginPage from "./pages/authorizationPages/LoginPage";

const AppRoutes = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Route
        path="/"
        element={<ProtectedRoute user="">{<Layout />}</ProtectedRoute>}
      >
        <Route path="/" element={<WorkingGroups />} breadcrumb={t("home")} />

        <Route
          path="/devices"
          element={<Devices />}
          breadcrumb={t("devices")}
        />

        <Route
          path="/devices/device"
          element={<Device />}
          breadcrumb={t("device")}
        />
      </Route>
      <Route path="/login" element={<LoginPage />}></Route>
    </>
  );
};
export default AppRoutes;
