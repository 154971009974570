import React from "react";
type Props = {
  children: JSX.Element;
};

const FluidContainer = ({ children }: Props) => {
  return (
    <div className="container p-3">
      <div className="container-fluid col-sm-12 col-lg-10">{children}</div>
    </div>
  );
};

export default FluidContainer;
