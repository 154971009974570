import { t } from "i18next";
import { Outlet } from "react-router-dom";
import { IContainerProps } from "../../../Interfaces/Props/IContainerProps";
import React from "react";

const DefaultArticleChild = (props: IContainerProps) => {
  return (
    <div className="border-top-0 border mx-md-3 mx-2 rounded-bottom shadow-sm">
      {props.children}
    </div>
  );
};

export default DefaultArticleChild;
