import React from "react";
import { Navigate, Route } from "react-router-dom";
import { getCurrentUser } from "../../../api/auth/authClient";

const ProtectedRoute = ({
  redirectPath = process.env.PUBLIC_URL + "/login",
  children,
}: any) => {
  const token = getCurrentUser();

  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;