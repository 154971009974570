import DefaultArticleChild from "../../../moleculars/Articles/DefaultArticleChild";
import LoadingContainer from "../../../moleculars/Containers/LoadingContainer";
import ErrorContainer from "../../../moleculars/Containers/ErrorContainer";
import IWorkingGroup from "../../../../Interfaces/Thor/IWorkingGroup";
import { IApiResponse } from "../../../../Interfaces/IApiResponse";
import WorkingGroupsList from "../../forms/WorkingGroupList";
import TextHeader from "../../../moleculars/headings/Title";
import ThorClient from "../../../../api/thor/ThorClient";
import { HiMiniUserGroup } from "react-icons/hi2";
import { Container } from "react-bootstrap";
import { useQuery } from "react-query";
import { t } from "i18next";

const WorkingGroups = () => {
  const { isLoading, isSuccess, isError, data } = useQuery<
    IApiResponse<Array<IWorkingGroup>>,
    Error
  >("get-working-groups", async () => {
    let workingGroups = await ThorClient.getWorkingGroups();

    return workingGroups;
  });

  return (
    <>
      <Container className="flex-column">
        <TextHeader
          message={t("thor-working-groups")}
          document=""
          icon={HiMiniUserGroup}
          showGoBackIcon={false}
          showRefreshIcon={true}
        ></TextHeader>

        <br></br>

        <DefaultArticleChild>
          {isLoading && <LoadingContainer />}
          {isError && !data?.succeeded && <ErrorContainer code={Number(400)} />}
          {isSuccess && !isLoading && (
            <WorkingGroupsList workingGroups={data.data} />
          )}
        </DefaultArticleChild>
      </Container>
    </>
  );
};

export default WorkingGroups;
