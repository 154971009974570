import DefaultArticleChild from "../../../moleculars/Articles/DefaultArticleChild";
import LoadingContainer from "../../../moleculars/Containers/LoadingContainer";
import ErrorContainer from "../../../moleculars/Containers/ErrorContainer";
import { IApiResponse } from "../../../../Interfaces/IApiResponse";
import { useLocation, useSearchParams } from "react-router-dom";
import Title from "../../../moleculars/headings/Title";
import IDevice from "../../../../Interfaces/Thor/IDevice";
import ThorClient from "../../../../api/thor/ThorClient";
import Container from "react-bootstrap/Container";
import { BsDeviceSsdFill } from "react-icons/bs";
import DeviceList from "../../forms/DeviceList";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { t } from "i18next";

const Devices = () => {
  const { search } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  let groupId = Number(searchParams.get("groupId"));

  useEffect(() => {
    groupId = Number(searchParams.get("groupId"));
  }, [search]);

  const { isLoading, isSuccess, isError, data } = useQuery<
    IApiResponse<Array<IDevice>>,
    Error
  >("get-devices", async () => {
    let devices:IApiResponse<Array<IDevice>> = await ThorClient.getDevices(groupId);

    return devices;
  });

  const findLatestDate = (devices:Array<IDevice> | undefined) => {
    if (devices === undefined || devices.length === 0) return "";

    let latestDate = devices[0].refreshDate;

    for (let i = 1; i < devices.length; i++) {
      if (devices[i].refreshDate > latestDate) {
        latestDate = devices[i].refreshDate;
      }
    }

    return latestDate.toString().replace("T", " ").substring(0, 16);
  };

  return (
    <>
      <Container className="flex-column">
        <Title
          message={t("thor-devices")}
          document=""
          icon={BsDeviceSsdFill}
          showGoBackIcon={true}
          showRefreshIcon={true}
          additionalInfo={findLatestDate(data?.data)}
        ></Title>
        <br></br>
        <DefaultArticleChild>
          {isLoading && <LoadingContainer />}
          {isError && !data?.succeeded && <ErrorContainer code={Number(400)} />}
          {isSuccess && !isLoading && <DeviceList devices={data.data} />}
        </DefaultArticleChild>
      </Container>
    </>
  );
};

export default Devices;
