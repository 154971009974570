import IUpdateDeviceRequest from "../../Interfaces/Thor/IUpdateDeviceRequest";
import IWorkingGroup from "../../Interfaces/Thor/IWorkingGroup";
import { IApiResponse } from "../../Interfaces/IApiResponse";
import IDevice from "../../Interfaces/Thor/IDevice";
import authHeader from "../auth/authClient";
import http from "../../axios/axios";

class ThorClient {
  getWorkingGroups = async () => {
    var response = await http.get<IApiResponse<Array<IWorkingGroup>>>(`/Thor/GetWorkingGroups`, {
      headers: authHeader()
    });

    return response.data;
  }

  getDevices = async (groupId: number) => {
    var response = await http.get<IApiResponse<Array<IDevice>>>(`/Thor/GetDevices/${groupId}`, {
      headers: authHeader()
    });

    return response.data;
  }
  
  getDevice = async (deviceId: number) => {
    var response = await http.get<IApiResponse<IDevice>>(`/Thor/GetDevice/${deviceId}`, {
      headers: authHeader()
    });

    return response.data;
  }

  updateDevice = async (device: Partial<IUpdateDeviceRequest>) => {
    var response = await http.put<IApiResponse<Boolean>>(`/Thor/UpdateDevice`, device, {
      headers: authHeader()
    });

    return response.data;
  }
}

export default new ThorClient();