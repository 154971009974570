import React from "react";
import ReactDOM from "react-dom/client";
import "./configs/i18n";
import { HashRouter, BrowserRouter } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import "./styles/main.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import log from "loglevel";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();
log.setLevel("TRACE");
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
