import React, { useEffect } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import { fromLonLat, get as getProjection } from 'ol/proj';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { isEmpty } from 'ol/extent';
import IDevicesProp from '../../../Interfaces/Props/IDevicesProp';

const DeviceMap: React.FC<IDevicesProp> = ({ devices }) => {
  useEffect(() => {

    const osmSource = new OSM({
      attributions: [],
    });

    // Add markers to the map
    const vectorSource = new VectorSource({
      features: devices
        .filter(device => device.latitude !== 0 && device.longitude !== 0)
        .map(device => {
          const feature = new Feature({
            geometry: new Point(fromLonLat([device.longitude, device.latitude])),
          });

          feature.setStyle(
            new Style({
              image: new Icon({
                anchor: [0.5, 1],
                src: process.env.PUBLIC_URL + '/img/mapPoint.png',
              }),
              text: new Text({
                text: device.deviceName, 
                offsetY: -65, 
                font: 'bold 16px Calibri,sans-serif',
                fill: new Fill({ color: '#ff0000' }),
                stroke: new Stroke({ color: '#fff', width: 2 }),
              }),
            })
          );

          return feature;
        }),
      strategy: bboxStrategy,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    const map = new Map({
      target: 'map', 
      layers: [
        new TileLayer({
          source: osmSource,
        }),
        vectorLayer
      ],
      view: new View({
        center: fromLonLat([21.0122, 52.2297]), // Default center if no devices
        zoom: 10, // Default zoom level
      }),
    });

    const extent = vectorSource.getExtent();
    if (!isEmpty(extent)) {
      map.getView().fit(extent, { size: map.getSize(), maxZoom: 18, padding: [50, 50, 50, 50] });
    }

    return () => {
      // Cleanup when component unmounts
      map.setTarget();
    };
  }, [devices]);

  return <div id="map" style={{ width: '100%', height: '600px' }}></div>;
};

export default DeviceMap;
