import Logo from "../../atoms/logos/Logo";
import HeaderNavbar from "../../moleculars/navbar/HeaderNavbar";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import LangSwitcher from "../Lists/langswitcher/langswitcher";
import LogoutButton from "../../atoms/buttons/LogoutButton";
import React from "react";

const DeafultHeader = () => {
  return (
    <Navbar
      bg="white"
      variant="light"
      expand="lg"
      z-index="1"
      className="nav_index"
    >
      <Container>
        <Logo />
        <Navbar.Toggle aria-controls="navbar-Toogle" />
        <Navbar.Collapse id="navbar-Toogle">
          <HeaderNavbar />
          <Col md="1"></Col>
          <LogoutButton />
          {/* <LangSwitcher /> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default DeafultHeader;
