import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    supportedLngs: ["pl", "en", "uk", "ru"],
    fallbackLng: "pl",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: process.env.PUBLIC_URL + "/locales/{{lng}}/translation.json",
    },
    returnNull: false,
  });

export default i18n;
