import "./configs/i18n";
import { Routes, useLocation } from "react-router-dom";
import "./index.css";
import { AnimatePresence } from "framer-motion";
import AppRoutes from "./AppRoutes";

function App() {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait" key={"testani"}>
      <Routes location={location} key={location.pathname}>
        {AppRoutes()}
      </Routes>
    </AnimatePresence>
  );
}

export default App;
