import { t } from "i18next";
import { Table } from "react-bootstrap";
import { CardList } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import IWorkingGroupProp from "../../../Interfaces/Props/IWorkingGroupProp";

const WorkingGroupList = (prop: IWorkingGroupProp) => {
  const navigate = useNavigate();

  return (
    <>
      {prop && prop.workingGroups.length > 0 ? (
        <Table bordered hover>
          <thead>
            <tr className="table-header-row">
              <th className="table-header-row">{t("groupName")}</th>
              <th className="table-header-row">{t("supervisor")}</th>
              <th className="table-header-row">{t("comments")}</th>
              <th className="table-header-icon-column table-header-row"></th>
            </tr>
          </thead>
          <tbody>
            {prop?.workingGroups?.map((group, index) => {
              return (
                <tr key={index}>
                  <td>{group.groupName}</td>
                  <td>{group.supervisor}</td>
                  <td>{group.comments}</td>
                  <td
                    className="article-header-icon icon-pointer"
                    onClick={() => {
                      navigate(`/devices?groupId=${group.id}`);
                    }}
                  >
                    <center>
                      <CardList size="20"></CardList>
                    </center>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="container p-3">
          <div className="container-fluid col-sm-12 col-lg-12">
            <div className="alert alert-light m-2 p-2" role="alert">
              <center>{t("no-working-groups-found")}</center>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkingGroupList;
