import { t } from "i18next";
import { SyntheticEvent, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IDeviceProp from "../../../Interfaces/Props/IDeviceProp";
import IUpdateDeviceRequest from "../../../Interfaces/Thor/IUpdateDeviceRequest";
import ThorClient from "../../../api/thor/ThorClient";

const DeviceForm = (prop: IDeviceProp) => {
  const [updateModel, setUpdateModel] = useState<Partial<IUpdateDeviceRequest>>(
    { id: prop.device?.id, deviceName: prop.device?.deviceName }
  );

  const [buttonActive, setButtonActive] = useState<boolean>(true);

  async function updateDevice() {
    if (updateModel && updateModel !== undefined) {
      let response = await ThorClient.updateDevice(updateModel);

      if (response.succeeded) {
        toast.success(t("save_succesful"), {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          onOpen: () => {
            setButtonActive(true);
          },
          onClose: () => {
            setButtonActive(false);
          },
        });
      } else {
        toast.error(t("save-error"), {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          onOpen: () => {
            setButtonActive(true);
          },
          onClose: () => {
            setButtonActive(false);
          },
        });
      }
    }
  }

  const onSubmitHandler = async (event: SyntheticEvent) => {
    event.preventDefault();
    updateDevice();
  };

  const openInNewTab = (url: string | undefined) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");

    if (newWindow) {
      newWindow.opener = null;
    }
  };

  return (
    <>
      <div className="border-bottom rounded-bottom">
        <Form className="p-4" onSubmit={onSubmitHandler}>
          <Row>
            <Col xs="12" md="6">
              <Form.Floating className="mb-3">
                <FloatingLabel
                  controlId="floatingTextarea"
                  label={t("deviceName")}
                  className="mb-3"
                >
                  <Form.Control
                    className="border-0 border-bottom "
                    type="text"
                    defaultValue={prop?.device?.deviceName}
                    onChange={(e) => {
                      setButtonActive(false);

                      setUpdateModel({
                        ...updateModel,
                        deviceName: e.target.value,
                      });
                    }}
                  />
                </FloatingLabel>
              </Form.Floating>
            </Col>

            <Col
              xs="12"
              md="6"
              onClick={() => openInNewTab(prop?.device?.googleMapsUrl)}
            >
              <Form.Floating className="mb-3">
                <FloatingLabel
                  controlId="floatingTextarea"
                  label={t("localization")}
                  className="mb-3"
                >
                  <Form.Control
                    className="border-0 border-bottom"
                    type="text"
                    placeholder=""
                    value={prop?.device?.googleMapsUrl}
                    disabled
                  />
                </FloatingLabel>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="2">
              <Form.Floating className="mb-3">
                <FloatingLabel
                  controlId="floatingTextarea"
                  label={t("refreshDate")}
                  className="mb-3"
                >
                  <Form.Control
                    className="border-0 border-bottom "
                    type="text"
                    placeholder=""
                    value={prop?.device?.refreshDate
                      .toString()
                      .substring(0, 19)
                      .replace("T", " ")}
                    disabled
                  />
                </FloatingLabel>
              </Form.Floating>
            </Col>

            <Col xs="12" md="2">
              <Form.Floating className="mb-3">
                <FloatingLabel
                  controlId="floatingTextarea"
                  label={t("lastActive")}
                  className="mb-3"
                >
                  <Form.Control
                    className="border-0 border-bottom "
                    type="text"
                    placeholder=""
                    value={
                      prop?.device?.lastActive &&
                      prop?.device?.lastActive
                        .toString()
                        .substring(0, 19)
                        .replace("T", " ")
                    }
                    disabled
                  />
                </FloatingLabel>
              </Form.Floating>
            </Col>

            <Col xs="12" md="2">
              <Form.Floating className="mb-3">
                <FloatingLabel
                  controlId="floatingTextarea"
                  label={t("voltage")}
                  className="mb-3"
                >
                  <Form.Control
                    className="border-0 border-bottom "
                    type="text"
                    placeholder=""
                    value={
                      prop?.device?.voltage == null
                        ? "0.00"
                        : prop?.device.voltage
                    }
                    disabled
                  />
                </FloatingLabel>
              </Form.Floating>
            </Col>

            <Col xs="12" md="2">
              <Form.Floating className="mb-3">
                <FloatingLabel
                  controlId="floatingTextarea"
                  label={t("temperature")}
                  className="mb-3"
                >
                  <Form.Control
                    className="border-0 border-bottom "
                    type="text"
                    placeholder=""
                    value={
                      prop?.device?.temperature == null
                        ? "0.00"
                        : prop?.device.temperature
                    }
                    disabled
                  />
                </FloatingLabel>
              </Form.Floating>
            </Col>

            <Col xs="12" md="2">
              <Form.Floating className="mb-3">
                <FloatingLabel
                  controlId="floatingTextarea"
                  label={t("humidity")}
                  className="mb-3"
                >
                  <Form.Control
                    className="border-0 border-bottom "
                    type="text"
                    placeholder=""
                    value={
                      prop?.device?.humidity == null
                        ? "0.00"
                        : prop?.device.humidity
                    }
                    disabled
                  />
                </FloatingLabel>
              </Form.Floating>
            </Col>

            <Col xs="12" md="2">
              <Form.Floating className="mb-3">
                <FloatingLabel
                  controlId="floatingTextarea"
                  label={t("direction")}
                  className="mb-3"
                >
                  <Form.Control
                    className="border-0 border-bottom "
                    type="text"
                    placeholder=""
                    value={
                      prop?.device?.direction == null
                        ? "0.00"
                        : prop?.device.direction
                    }
                    disabled
                  />
                </FloatingLabel>
              </Form.Floating>
            </Col>
          </Row>

          <br />

          <Row className="d-flex justify-content-end">
            <Col xs="12" md="2" lg="1">
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                disabled={buttonActive}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>

          <ToastContainer />
        </Form>
      </div>
    </>
  );
};

export default DeviceForm;
