import React from "react";
import Logo from "../../atoms/logos/Logo";
import HeaderNavbar from "../../moleculars/navbar/HeaderNavbar";
import { Container, Navbar } from "react-bootstrap";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import LangSwitcher from "../Lists/langswitcher/langswitcher";

const LoginHeader = () => {
  return (
    <Navbar bg="white" variant="light" expand="lg" className="">
      <Container>
        <Navbar.Toggle aria-controls="navbarScroll" />
        {/* <LangSwitcher /> */}
      </Container>
    </Navbar>
  );
};

export default LoginHeader;
