import DefaultArticleChild from "../../../moleculars/Articles/DefaultArticleChild";
import LoadingContainer from "../../../moleculars/Containers/LoadingContainer";
import ErrorContainer from "../../../moleculars/Containers/ErrorContainer";
import { IApiResponse } from "../../../../Interfaces/IApiResponse";
import { useLocation, useSearchParams } from "react-router-dom";
import TextHeader from "../../../moleculars/headings/Title";
import IDevice from "../../../../Interfaces/Thor/IDevice";
import ThorClient from "../../../../api/thor/ThorClient";
import Container from "react-bootstrap/Container";
import DeviceForm from "../../forms/DeviceForm";
import { MdEditDocument } from "react-icons/md";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { t } from "i18next";

const Device = () => {
  const { search } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  let deviceId = Number(searchParams.get("deviceId"));

  useEffect(() => {
    deviceId = Number(searchParams.get("deviceId"));
  }, [search]);

  const { isLoading, isSuccess, isError, data } = useQuery<
    IApiResponse<IDevice>,
    Error
  >("get-device", async () => {
    let device = await ThorClient.getDevice(deviceId);

    return device;
  });

  return (
    <>
      <Container className="flex-column">
        <TextHeader
          message={t("thor-device-form")}
          document=""
          icon={MdEditDocument}
          showGoBackIcon={true}
          showRefreshIcon={true}
        ></TextHeader>
        <br></br>
        <DefaultArticleChild>
          {isLoading && <LoadingContainer />}
          {isError && !data?.succeeded && <ErrorContainer code={Number(400)} />}
          {isSuccess && !isLoading && (
            <DeviceForm device={data.data}></DeviceForm>
          )}
        </DefaultArticleChild>
      </Container>
    </>
  );
};

export default Device;
