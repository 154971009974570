import http from "../../axios/axios";
import { ILoginUser } from "../../Interfaces/Users/ILoginUser";
import { IToken } from "../../Interfaces/Users/IToken";
import config from "../../configs/config.json";
import { IApiResponse } from "../../Interfaces/IApiResponse";

export const restLogin = async (
  iLoginUser: Partial<ILoginUser> | undefined
) => {
  const response = await http.post<IApiResponse<IToken>>("auth/login", iLoginUser);
  
  if (response.data && response.data.succeeded && response.data.data.token) {
    localStorage.setItem("token", JSON.stringify(response.data.data));
  }
  return response.data.data;
};

export const redirectToMicrosoftLoginPage = async () => {
  const redirectUrl = getRedirectUrlToMicrosoft();

  window.location.href = redirectUrl;
};

export const getRedirectUrlToMicrosoft = () => {
  let redirectUrl: string = config.AUTHORIZATION_ENDPOINT;
  redirectUrl += "?response_type=" + config.RESPONSE_TYPE;
  redirectUrl += "&client_id=" + config.CLIENT_ID;
  redirectUrl += "&client_secret=" + config.CLIENT_SECRET;
  redirectUrl += "&redirect_uri=" + config.REDIRECT_URI;
  redirectUrl += "&scope=" + config.SCOPE;

  return redirectUrl;
};

export const loginWithMicrosoftToken = async (accessToken: Partial<IToken>) => {
  const response = await http.post<IToken>(
    "Auth/LoginWithMicrosoftToken",
    accessToken
  );

  if (response.data.token) {
    localStorage.setItem("token", JSON.stringify(response.data))
  }

  return response.data;
};

export const logout = () => {
  localStorage.removeItem("token");
  let redirectPath = process.env.PUBLIC_URL + "/login";

  window.location.href = redirectPath;
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("token");
  if (userStr) return userStr;

  return null;
};

export default function authHeader() {
  const userStr = localStorage.getItem("token");
  let user = null;

  if (userStr) user = JSON.parse(userStr);

  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  } else {
    return { Authorization: "" };
  }
}
