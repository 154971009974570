import { Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Logo = () => {
  const { t } = useTranslation();
  return (
    <Navbar.Brand>
      <img
        src={process.env.PUBLIC_URL + "/img/logo.png"}
        className="logo__image img-fluid"
        alt={t("homepage").toString()}
        width="200"
      ></img>
    </Navbar.Brand>
  );
};

export default Logo;
